import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'


import pic22 from '../assets/images/p22.jpg'
import pic24 from '../assets/images/p24.jpg'
import pic26 from '../assets/images/p26.jpg'

const Revelarte = (props) => (
    <Layout>
        <Helmet>
            <title>RevelARTE</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding title='RevelARTE' class='none' subtitle='Creación a través del arte y el juego'/>

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Te contamos de esta Iniciativa</h2>
                    </header>
                    <p>Partimos de que la creatividad es un proceso que se trabaja y explora desde nuestro
autoconocimiento,  búsquedas, deseos y motivaciones, siendo el juego y los recursos artísticos los medios que nos ayudan a manifestar estos múltiples procesos y transformaciones. 
</p>                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                
                    <div className="content-lines">
                            
                            <p>Es por ello que pensamos a RevelARTE como un centro cultural virtual que puede generar distintos elementos para trabajar la creatividad, albergarlos y compartirlos.  </p>
                           
                            <h2>Nuestras Lineas</h2>
                        
    <div class="grid-wrapper">
                                <div className='box col-3'><h3 to="/talleres" className=" icon ">Talleres</h3>
                                <p>Que permitan acercarnos a procesos creativos y de autoconocimiento.</p>

                                <Link to="/talleres" className="link " >Ver</Link>

                                </div>
                                <div className='box  col-3'><h3 to="/elarte" className=" icon ">Entrevistas</h3>
                                <p>Intercambio con personas en distintos roles de la vida, contándonos  cómo se acercan a sus procesos creativos.
                                </p></div>
                                <div className='box  col-3'><h3 to="/saludmental" className=" icon ">Biblioteca</h3>
                                <p>Contenidos para profundizar, artículos, pdf, links y otros.  </p>    </div>
                                <div className='box  col-3  '><h3 to="/saludmental" className=" icon ">Tienda</h3>  
                                <p> Contaremos con algunos productos que nos permitan trabajar la creatividad. </p> </div>    
                                </div>
                     </div>
                </section>
              
                <section      >
                        
                    
                   
                    <div className="content-objetivos"  >
                        <div className="inner ">

                        <header className="major">
                                <h3>Objetivos de RevelARTE</h3>
                            </header>
                        <div class="grid-wrapper">
                        <div class="col-6">

                        <img src={pic22} className='image' />   
                        </div>
                            <div class="col-6"><ul>
                                <li> Generar espacios a través del arte que propicien la creatividad, el juego y el
tránsito por sensaciones y emociones diferentes, como punto de partida
para nuevas formas de ser y estar.</li>
<li> Propiciar encuentros con otrxs y con uno mismo, que permitan develar-nos
ante nosotrxs y  ante otrxs como seres vivientes, conectados.</li>

<li> Cuestionar las formas actuales de vivir, rompiendo con las automatizaciones en
pos de procesos más creativos llenos de pulsión vital.</li>
<li> Incentivar el juego y la imaginación para poder dar forma y comunicar las
emociones que nos habitan.</li>
</ul></div>
                                 
                                   </div>


                        </div>
                    </div>
                </section>

                <section>
                   
                    <div className="content-ejes">
                        <div className="inner">
                        <div class="grid-wrapper">
                       
<div class="col-6">


                            <header className="major">
                                <h3>Ejes</h3>
                            </header>

                           
                            <p>
                            Para explicar en qué se basa nuestra propuesta  nos ayudamos de nuestra experiencia y también de autores quienes, con mucha claridad, nos han podido ayudar a poner en palabras estas búsquedas. Algunas preguntas que nos hicimos: Cómo definimos el Arte?, Por qué es importante conectar con la imaginación?, Por qué trabajar la creatividad?, Por qué propiciar encuentros con otrxs o con uno mismo que permitan develar-nos?. Dentro de esta sección encontrarás algunas respuestas a estas preguntas, explicaciones y definiciones! 
</p>

                           
                            </div>
                            <div class="col-6">
                        <img src={pic24} className='image'/>
</div>
                            </div>  </div>

                            <ul className="actions">
                                <li><Link to="/creatividad" className="button special big">Creatividad</Link></li>
                                <li><Link to="/elarte" className="button special big">Arte</Link></li>
                                <li><Link to="/saludmental" className="button special big">Salud Mental</Link></li>

                            </ul>
                            </div> 
                </section>
                <section>
                     
                    <div className="content-somos">
                        <div className="inner">
                        <header className="major">
                                <h3>Quiénes vamos siendo?</h3>
                            </header>
                        <div className="grid-wrapper">
                            <div className="col-7 "><p>
Somos Flor y Gri. Somos amigas. Psicólogas sociales. Somos quietud y
movimiento, luz y sombra. Somos lo familiar y lo desconocido. Somos juego y
reflexión… Somos como vos.
 <br />  <br />Hemos realizado  diversos trabajos  en grupos operativos donde se interviene alrededor de los elementos del proceso grupal  (pertinencia, cooperación, comunicación,aprendizaje, etc.), así como también  en talleres que abordan diferentes temáticas como: El impacto en los cuerpos de las violencias por motivos de género, construcción de la economía social y solidaria, el juego en la vida cotidiana, entre otros, utilizando en su desarrollo técnicas lúdicas y psicodramáticas,que permiten integrar al cuerpo, la psique y lo colectivo como ejes en la elaboración de las distintas situaciones. Promoviendo la creatividad como un estado que habilita y refuerza la adaptación activa de la realidad y por tanto la salud metal.</p>
</div>
                                <div className="box col-5"> <h5>Flor Giovini</h5> Psicóloga Social. Estudiante de Astrología. Aprendiz de la vida.
Artesana y descendiente de artesanas. Bruja de colores y amiga de la Luna. Me gusta
bailar, es mi manera de meditar. Soy curiosa. Aficionada de los oráculos y a los espacios
de autogestación y del hacer colectivo.</div>

<div className="col-7"><div className='image'>
                        <img src={pic26 } alt="" /></div>
                        </div>
<div className="box col-5"><h5>Grisel Pinto</h5> Soy Psicóloga . Viajé a Buenos Aires,  en donde estudié psicología social y psicodrama, incorporando la mirada  corporal y vincular en el trabajo que realizo. Hoy mi búsqueda se sigue nutriendo a través del arte,  habilitando esta creación para mí y también para otrxs.
</div>

       

                                   </div>


                        </div>
                    </div>
                </section>
  
            </section>
        </div>

    </Layout>
)

export default Revelarte